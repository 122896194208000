import React from 'react';
import * as Icon from 'react-feather';

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Mail />
                            </div>
                            <h3>Mailimiz</h3>
                            <p><a aria-label="mail"   href="mailto:finans@pixelyazilim.com.tr">finans@pixelyazilim.com.tr</a></p>
                                </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>
                            <h3>Buradayız</h3>
                            <p>Bahçeşehir Mh. Çöven Sokak No:15 Meram / KONYA</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Phone />
                            </div>
                            <h3>Arayabilirsiniz</h3>
                            <p><a  aria-label="telefon"   href="tel:+905419388298">+90 541 938 82 98</a></p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;  